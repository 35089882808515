import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import ReactQuill from "react-quill";
import ImageWebp from "../../ImageWebp";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loader from "../Admin/Dashboard/Loader";
import { withRouter } from "react-router-dom";
import axios from "axios";
import baseApi from "../api";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditComment = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [comment, setComment] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${baseApi}blog-comment/get-comment/${id}`).then((res) => {
      setComment(res.data.data);
      setIsloading(false);
      //   console.log("Res", res);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: comment.name,
      email: comment.email,
      your_website: comment.your_website || "",
      comment: comment.comment
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      email: Yup.string().required("Please enter email"),
      your_website: Yup.string(),
      comment: Yup.string().required("Please enter comment")
    }),
    onSubmit: (values) => {
      setIsloading(true);

      let dataObj = {
        comment_id: comment._id,
        name: values.name,
        email: values.email,
        comment: values.comment
      };

      if (values.your_website !== "") {
        dataObj["your_website"] = values.your_website;
      }
      let reqObj = {
        method: "PUT",
        url: `${baseApi}blog-comment/update-comment`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
        },
        data: dataObj
      };

      //   console.log("Akshay");
      axios(reqObj)
        .then((res) => {
          setIsloading(false);
        })
        .catch((err) => {
          setIsloading(false);
        });
    }
  });

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote", "code-block"], // toggled buttons
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }]
    ]
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "code-block",
    "font",
    "size",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "video"
  ];

  return (
    <Fragment>
      <div className="admin_panel">
        <Dashboard />

        {!isLoading ? (
          <div className="container">
            <div className="col-sm-12">
              {" "}
              <CurrentDate />
              <form onSubmit={formik.handleSubmit} encType="application/json">
                <h3 className="mt-2">
                  Blog Title :{" "}
                  {comment
                    ? comment.blog_id
                      ? comment.blog_id.b_title
                      : ""
                    : ""}
                </h3>
                <div className="form-group">
                  <label>
                    <h3>Name</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    {...formik.getFieldProps("name")}
                  />

                  <span className="text-danger">
                    {formik.touched.name && formik.errors.name ? (
                      <div>{formik.errors.name}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Email</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    {...formik.getFieldProps("email")}
                  />

                  <span className="text-danger">
                    {formik.touched.email && formik.errors.email ? (
                      <div>{formik.errors.email}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Website Link</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="your_website"
                    name="your_website"
                    {...formik.getFieldProps("your_website")}
                  />

                  <span className="text-danger">
                    {formik.touched.your_website &&
                    formik.errors.your_website ? (
                      <div>{formik.errors.your_website}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Comment</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="comment"
                    name="comment"
                    {...formik.getFieldProps("comment")}
                  />

                  <span className="text-danger">
                    {formik.touched.comment && formik.errors.comment ? (
                      <div>{formik.errors.comment}</div>
                    ) : null}
                  </span>
                </div>

                <hr />

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Update Comment"
                  />
                  &nbsp;&nbsp;
                  <Link to="/comments">Back to Comments</Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Fragment>
  );
};

export default EditComment;
