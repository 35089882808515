import React, { Component } from "react";
import Dashboard from "./Dashboard";
import baseApi from "../../api";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CurrentDate from "../CurrentDate";
import { Helmet } from "react-helmet";
import Select from "react-select";
import "./admin.css";
import Loader from "./Loader";
import "../../../App.css";

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export default class AddnewBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      b_title: "",
      b_description: "",
      page_title: "",
      page_description: "",
      keyword: "",
      b_titleErr: "",
      b_descriptionErr: "",
      page_titleErr: "",
      page_descriptionErr: "",
      imageErr: "",
      keywordErr: "",
      image: [],
      author_name: "",
      author_nameErr: "",
      // author_image: "",
      // author_imageErr: "",
      title: "Add new Blog",
      loading: false,
      category_options: [],
      author_options: [],
      category: [],
      categoryErr: "",
      slug: "",
      slugErr: ""
    };
    this.modules = {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "blockquote", "code-block"], // toggled buttons
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }]
      ]
    };

    this.formats = [
      "header",
      "bold",
      "italic",
      "underline",
      "blockquote",
      "code-block",
      "font",

      "size",
      "link",
      "image",
      "video",

      "list",
      "bullet",
      "align",
      "color",
      "background"
    ];

    this.onchangeb_title = this.onchangeb_title.bind(this);
    this.onchangeb_description = this.onchangeb_description.bind(this);
    this.onchangePageTitle = this.onchangePageTitle.bind(this);
    this.onchangePageDes = this.onchangePageDes.bind(this);
    this.onchangeKeyword = this.onchangeKeyword.bind(this);
    this.onchangeAuthorName = this.onchangeAuthorName.bind(this);
    // this.onchangeAuthorImage = this.onchangeAuthorImage.bind(this);
    this.onchangeImage = this.onchangeImage.bind(this);
    this.onchangeCategory = this.onchangeCategory.bind(this);
    this.onchangeSlug = this.onchangeSlug.bind(this);
    this.checkSlug = this.checkSlug.bind(this);
    this.debouncedCheckSlug = debounce(this.checkSlug, 1000);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    axios.get(`${baseApi}blog/get-blog-categories`).then((res) => {
      console.log("category_res", res.data.data);
      this.setState({
        category_options: res.data.data.map((item) => {
          return { label: item.category, value: item.slug };
        })
      });
    });
    axios.get(`${baseApi}blog/get-blog-authors`).then((res) => {
      console.log("category_res", res.data.data);
      this.setState({
        author_options: [...res.data.data]
      });
    });
  }

  checkSlug(slug) {
    axios
      .post(
        baseApi + "blog/check-slug",
        { slug: slug },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
          }
        }
      )
      .then((res) => {
        // console.log("Res...", res);
        this.setState({
          slugErr: ""
        });
      })
      .catch((error) => {
        // console.log("error...", error.response.data.error);
        this.setState({
          slugErr: error.response.data.error || "Slug is invalid"
        });
      });
  }

  onchangeb_title(e) {
    this.setState({
      b_title: e.target.value,
      b_titleErr: ""
    });
  }

  onchangeSlug(e) {
    this.setState({
      slug: e.target.value
    });

    this.debouncedCheckSlug(e.target.value);
  }

  onchangeImage(e) {
    this.setState({
      image: e.target.files[0],
      imageErr: ""
    });
  }
  // onchangeAuthorImage(e) {
  //   this.setState({
  //     author_image: e.target.files[0],
  //     author_imageErr: ""
  //   });
  // }
  onchangeb_description(value) {
    this.setState({
      b_description: value,
      b_descriptionErr: ""
    });
  }
  onchangeCategory(value) {
    const newValues = value.map((item) => item.value);
    // console.log("newValues", newValues);
    this.setState({
      category: newValues,
      categoryErr: ""
    });
  }
  onchangePageTitle(e) {
    this.setState({
      page_title: e.target.value,
      page_titleErr: ""
    });
  }
  onchangePageDes(e) {
    this.setState({
      page_description: e.target.value,
      page_descriptionErr: ""
    });
  }
  onchangeKeyword(e) {
    this.setState({
      keyword: e.target.value,
      keywordErr: ""
    });
  }
  onchangeAuthorName(e) {
    this.setState({
      author_name: e.target.value,
      author_nameErr: ""
    });
  }
  handleValidation() {
    let isErr = false;
    if (this.state.b_title === "") {
      this.setState({ b_titleErr: "Enter Blog Title" });
      isErr = true;
    }

    if (this.state.b_description === "") {
      this.setState({ b_descriptionErr: "Enter Blog Description" });
      isErr = true;
    }
    if (this.state.page_title === "") {
      this.setState({ page_titleErr: "Enter Blog Page Title" });
      isErr = true;
    }
    if (this.state.page_description === "") {
      this.setState({ page_descriptionErr: "Enter Page Description" });
      isErr = false;
    }
    if (this.state.keyword === "") {
      this.setState({ keywordErr: "Enter Keyword" });
      isErr = true;
    }
    if (this.state.image.length === 0) {
      this.setState({ imageErr: "Please attach Image" });
      isErr = true;
    }
    if (this.state.category.length === 0) {
      this.setState({ categoryErr: "Please select category" });
      isErr = true;
    }
    return isErr;
  }

  onSubmit(e) {
    const Myswal = withReactContent(Swal);
    e.preventDefault();

    let validate = this.handleValidation();
    const fd = new FormData();
    fd.append("b_title", this.state.b_title);
    fd.append("b_description", this.state.b_description);
    fd.append("page_title", this.state.page_title);
    fd.append("page_description", this.state.page_description);
    fd.append("keyword", this.state.keyword);
    fd.append("image", this.state.image);
    if (this.state.author_name) {
      console.log("this.state.author_name", this.state.author_name);
      let authors = this?.state?.author_options?.filter(
        (author) => author.slug == this.state.author_name
      );
      console.log("authors", authors);
      authors = authors[0];
      fd.append("author_name", authors["name"]);
      fd.append("author_image", authors["author_image"]);
      fd.append("author_slug", String(authors["slug"]));
      fd.append("author_id", authors["_id"]);
    }
    // if (this.state.author_image) {

    //   fd.append("author_image", authors this.state.author_image);
    // }
    if (this.state.category) {
      fd.append("blog_category", JSON.stringify(this.state.category));
    }
    if (this.state.slug) {
      fd.append("slug", this.state.slug);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    };
    if (!validate) {
      this.setState({
        loading: true
      });
      axios
        .post(baseApi + "blog/add-blog", fd, config)
        .then((res) => {
          this.setState({
            loading: false
          });
          Myswal.fire({
            title: "Hurrah",
            text: "Blog is Added Successfully",
            type: "success"
          }).then((result) => {
            window.location.reload();
          });
        })
        .catch((error) => {
          this.setState({
            loading: false
          });

          Myswal.fire({
            title: "Something went wrong.",
            text: error?.response?.data?.message || "Server Error",
            type: "error"
          });
          // .then((result) => {
          //   window.location.reload();
          // });
        });
    }
  }

  render() {
    const hStyle = { color: "red" };
    return (
      <div>
        <Helmet>
          <title>Add Blog | Dean Infotech Pvt. Ltd.</title>
          <meta
            id="meta-description"
            name="description"
            content={this.state.title}
          />
          <meta
            id="og-keywords"
            name="keywords"
            property="og:keywords"
            content={this.state.title}
          />
          <meta id="og-title" property="og:title" content={this.state.title} />
        </Helmet>

        <Dashboard />
        {!this.state.loading ? (
          <div className="container">
            <div className="col-sm-12">
              <CurrentDate />
              <form onSubmit={this.onSubmit} encType="multipart/form-data">
                <div className="form-group">
                  <label>
                    <h3>Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.b_title}
                    onChange={this.onchangeb_title}
                  />
                  <span style={hStyle}>{this.state.b_titleErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Slug (Optional)</h3>
                  </label>
                  <input
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    className="form-control"
                    value={this.state.slug}
                    onChange={this.onchangeSlug}
                  />
                  <span style={hStyle}>{this.state.slugErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h4>Description</h4>
                  </label>
                  <ReactQuill
                    theme="snow"
                    value={this.state.b_description}
                    onChange={this.onchangeb_description}
                    modules={this.modules}
                    formats={this.formats}
                  />
                  <span style={hStyle}>{this.state.b_descriptionErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Category</h3>
                  </label>
                  <Select
                    defaultValue={[]}
                    isMulti
                    name="colors"
                    options={this.state.category_options}
                    className="form-Control select-search basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.onchangeCategory}
                  />
                  <span style={hStyle}>{this.state.categoryErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Author Name</h3>
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    value={this.state.author_name}
                    onChange={this.onchangeAuthorName}
                  >
                    <option value="">select</option>
                    {this.state.author_options.map((author) => {
                      return <option value={author.slug}>{author.name}</option>;
                    })}
                  </select>
                  <span style={hStyle}>{this.state.author_nameErr}</span>
                </div>

                {/* <div className="form-group">
                  <label>
                    <h4>Author Image</h4>
                  </label>
                  <input
                    type="file"
                    className="form-Control"
                    onChange={this.onchangeAuthorImage}
                    name="author_image"
                  />
                  <span style={hStyle}>{this.state.author_imageErr}</span>
                </div> */}

                <div className="form-group">
                  <label>
                    <h4>Image</h4>
                  </label>
                  <input
                    type="file"
                    className="form-Control"
                    onChange={this.onchangeImage}
                    name="Image"
                  />
                  <span style={hStyle}>{this.state.imageErr}</span>
                </div>
                <hr />
                <div className="form-group">
                  <label>
                    <h3>Page Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.page_title}
                    onChange={this.onchangePageTitle}
                  />
                  <span style={hStyle}>{this.state.page_titleErr}</span>
                </div>
                <div className="form-group">
                  <label>
                    <h3>Page Description</h3>
                  </label>
                  <textarea
                    className="form-control"
                    value={this.state.page_description}
                    onChange={this.onchangePageDes}
                  ></textarea>
                  <span style={hStyle}>{this.state.page_descriptionErr}</span>
                </div>
                <div className="form-group">
                  <label>
                    <h3>Keywords</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.keyword}
                    onChange={this.onchangeKeyword}
                  />
                  <span style={hStyle}>{this.state.keywordErr}</span>
                </div>

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Add Blog"
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}
