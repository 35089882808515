import LoadingSpin from "react-loading-spin";
import React, { Fragment } from "react";

export default function Loader() {
  let size = "45px";
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh" // Ensure the loader takes the full height of the viewport
        }}
      >
        <div className="spinning-loader">
          <LoadingSpin
            duration="2s"
            width="5px"
            timingFunction="ease-in-out"
            direction="alternate"
            size={size}
            primaryColor="rgb(0, 96, 144) rgb(0, 126, 255)"
            secondaryColor=" red rgb(32 149 207)"
            numberOfRotationsInAnimation={2}
          />
        </div>
      </div>
    </Fragment>
  );
}
