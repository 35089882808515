import axios from "axios";
import React, { Fragment, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Table/Table";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import Header from "../Admin/Dashboard/Header";
import baseApi from "../api";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const base_url = process.env.REACT_APP_BASE_URL;
const BlogCategory = () => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [itemTODelete, setItemToDelete] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEdit = (id) => {
    // dispatch(appActions.setActiveEditLocationId(id));
    // localStorage.setItem("activeEditLocationId", id);
    // dispatch(appActions.setActiveEditType("Company"));
    // localStorage.setItem("activeEditType", "Company");
    // navigate("/editLocation");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${baseApi}blog/remove-blog-category/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const columns = [
    {
      name: "Blog Category",
      selector: (row) => `${row.blog_category}`,
      sortable: true,
      width: "330px",
      wrap: true
    },
    {
      name: "Page title",
      selector: (row) => `${row.page_title}`,
      sortable: true,
      width: "330px",
      wrap: true
    },
    {
      name: "Page Description",
      selector: (row) => `${row.page_description}`,
      sortable: true,
      width: "330px",
      wrap: true
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD MMM YYYY"),
      sortable: true,
      width: "120px"
    },
    {
      name: "Action",
      cell: (row) => (
        <Fragment>
          <div>
            <div>
              <a
                href="#0"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setItemToDelete(row._id);
                  openModal();
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
              &nbsp;&nbsp;&nbsp;
              <Link to={"/edit-blog-category/" + row._id}>
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </Link>
              &nbsp;&nbsp;&nbsp;
              {/* <Link to={"/blog/" + row.slug}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </Link> */}
            </div>
          </div>
        </Fragment>
      )
    }
  ];
  const options = {
    // title: activeCompany.name,
    title: "Blog Category",
    type: "Blog Category",
    columns: columns,
    apiUrl: `${baseApi}blog/list-blog-category`,
    showAddBtn: true,
    AddBtnUrl: "/add-blog-category"
  };

  return (
    <Fragment>
      <Header title="Blog Category">
        <Table options={options} isLoading={isLoading} />
        <ConfirmModal
          isOpen={modalOpen}
          onClose={closeModal}
          type={"Blog Category"}
          content={"Are you sure you want to delete this category?"}
          handleDelete={handleDelete}
          id={itemTODelete}
        />
      </Header>
    </Fragment>
  );
};

export default BlogCategory;
