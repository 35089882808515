import { Fragment, useState } from "react";
import Header from "../Admin/Dashboard/Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../Admin/Dashboard/Loader";
import axios from "axios";
import baseApi from "../api";

import { Helmet } from "react-helmet";
import "../Admin/Dashboard/admin.css";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const Myswal = withReactContent(Swal);

const ChangePassword = (props) => {
  const [isLoading, setIsloading] = useState(false);
  //   console.log("auth", props.auth.user.email);

  const email = props.auth.user.email;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("Please enter new password")
        .min(6, "Password must be at least 6 characters long")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).*$/,
          "Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character"
        ),
      confirm_password: Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    }),
    onSubmit: (values) => {
      setIsloading(true);
      let dataObj = {
        email: email,
        password: values.new_password
      };

      let reqObj = {
        method: "POST",
        url: `${baseApi}auth/reset-password`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
        },
        data: dataObj
      };

      axios(reqObj)
        .then((res) => {
          setIsloading(false);
          formik.resetForm();
          Myswal.fire({
            title: "Hurrah",
            text: "Password reset Successfully",
            type: "success"
          }).then((result) => {
            // window.location.reload();
          });
        })
        .catch((err) => {
          setIsloading(false);
        });
    }
  });
  return (
    <Fragment>
      <Header title="Reset password">
        {!isLoading && (
          <form className="reset-form" onSubmit={formik.handleSubmit}>
            <div>
              <label for="new_password" class="form-label">
                New Password
              </label>
              <input
                type="text"
                className="form-control mb-3"
                id="new_password"
                name="new_password"
                {...formik.getFieldProps("new_password")}
              />

              <span className="text-danger mb-3">
                {formik.touched.new_password && formik.errors.new_password ? (
                  <div>{formik.errors.new_password}</div>
                ) : null}
              </span>
            </div>

            <div>
              <label for="confirm_password" class="form-label">
                Confirm Password
              </label>
              <input
                type="text"
                className="form-control mb-3"
                id="confirm_password"
                name="confirm_password"
                {...formik.getFieldProps("confirm_password")}
              />

              <span className="text-danger mb-3">
                {formik.touched.confirm_password &&
                formik.errors.confirm_password ? (
                  <div>{formik.errors.confirm_password}</div>
                ) : null}
              </span>
            </div>

            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        )}
        {isLoading && <Loader />}
      </Header>
    </Fragment>
  );
};

ChangePassword.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(ChangePassword);

// export default ChangePassword;
