import React, { useState } from "react";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import "./ConfirmModal.css";

const ConfirmModal = ({ isOpen, onClose, type, content, handleDelete, id }) => {
  return (
    <PureModal
      header={<div className="modal-header">Delete {type}</div>}
      footer={
        <div className="modal-btn-container">
          <button className="btn-cancel" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn-delete"
            onClick={() => {
              handleDelete(id);
              onClose();
            }}
          >
            Delete
          </button>
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <p>{content}</p>
    </PureModal>
  );
};

export default ConfirmModal;
