import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Dashboard from "./Dashboard";
import baseApi from "../../api";
import { Link, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import ImageWebp from "../../../ImageWebp";
import CurrentDate from "../CurrentDate";
import Loader from "./Loader";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const Myswal = withReactContent(Swal);

const EditBlog = (props) => {
  const [state, setState] = useState({
    b_title: "",
    b_description: "",
    image: [],
    page_title: "",
    page_description: "",
    keyword: "",
    title: "Edit Blog",
    tempImg: [],
    author_name: "",
    author_nameErr: "",
    author_image: "",
    author_image_temp: "",
    author_imageErr: "",
    updatedAuthorImage: "",
    updatedImage: "",
    loading: true,
    category_options: [],
    author_options: [],
    category: [],
    categoryErr: "",
    initialCategory: [],
    slug: "",
    slugErr: "",
    initialSlug: ""
  });

  const { id } = useParams();

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote", "code-block"],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "code-block",
    "font",
    "size",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "video"
  ];

  useEffect(() => {
    const fetchData = async (tempCategory) => {
      try {
        const response = await axios.get(`${baseApi}blog/get-blog-by-id/${id}`);
        console.log("response", response);
        setState((prevState) => ({
          ...prevState,
          b_title: response.data.data.b_title,
          b_description: response.data.data.b_description,
          page_title: response.data.data.page_title,
          page_description: response.data.data.page_description,
          keyword: response.data.data.keyword,
          image: response.data.data.image,
          tempImg: response.data.data.image,
          // author_image: response.data.data?.author_image,
          author_image_temp: response.data.data?.author_image,
          author_name: response.data.data?.author_id,
          author_slug: response.data.data?.author_slug,
          // author_id: response.data.data.author_id,
          slug: response.data.data.slug,
          initialSlug: response.data.data.slug,
          loading: false,
          category: response.data.data?.blog_category?.map((item) => {
            // return {
            //   label: tempCategory[item].category,
            //   value: tempCategory[item].slug
            // };
            return tempCategory.filter((ele) => ele.value == item)[0];
          })
        }));
      } catch (error) {
        console.error(error);
      }
    };

    axios.get(`${baseApi}blog/get-blog-categories`).then((res) => {
      const tempCategory = [];
      setState((prevState) => ({
        ...prevState,
        category_options: res.data.data.map((item) => {
          tempCategory.push({ label: item.category, value: item.slug });
          return { label: item.category, value: item.slug };
        })
      }));
      fetchData(tempCategory);
    });

    axios.get(`${baseApi}blog/get-blog-authors`).then((res) => {
      setState((prevState) => ({
        ...prevState,
        author_options: [...res.data.data]
      }));
    });
  }, [id, state.loading]);

  // useEffect(() => {

  // }, []);

  const checkSlug = (slug) => {
    axios
      .post(
        baseApi + "blog/check-slug",
        { slug: slug },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
          }
        }
      )
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          slugErr: ""
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          slugErr: error.response.data.error || "Slug is invalid"
        }));
      });
  };

  const onchangeb_title = (e) => {
    setState((prevState) => ({
      ...prevState,
      b_title: e.target.value
    }));
  };

  const onchangeb_description = (value) => {
    setState((prevState) => ({
      ...prevState,
      b_description: value
    }));
  };

  const onchangeCategory = (value) => {
    // const newValues = value.map((item) => item.label);
    setState((prevState) => ({
      ...prevState,
      category: value,
      categoryErr: ""
    }));
  };

  const onchangeImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setState((prevState) => ({
          ...prevState,
          image: file,
          updatedImage: file,
          base64Image: reader.result,
          tempImg: reader.result
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  // const onchangeAuthorImage = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       setState((prevState) => ({
  //         ...prevState,
  //         author_image: file,
  //         updatedAuthorImage: file,
  //         author_image_temp: reader.result
  //       }));
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const onchangePageTitle = (e) => {
    setState((prevState) => ({
      ...prevState,
      page_title: e.target.value
    }));
  };

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedCheckSlug = debounce(checkSlug, 1000);

  const onchangeSlug = (e) => {
    setState((prevState) => ({
      ...prevState,
      slug: e.target.value
    }));

    if (state.initialSlug !== e.target.value) {
      debouncedCheckSlug(e.target.value);
    }
  };

  const onchangePageDes = (e) => {
    setState((prevState) => ({
      ...prevState,
      page_description: e.target.value
    }));
  };

  const onchangeKeyword = (e) => {
    setState((prevState) => ({
      ...prevState,
      keyword: e.target.value
    }));
  };

  const onchangeAuthorName = (e) => {
    setState((prevState) => ({
      ...prevState,
      author_name: e.target.value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      loading: true
    }));

    const fd = new FormData();
    fd.append("blog_id", id);
    fd.append("b_title", state.b_title);
    fd.append("b_description", state.b_description);
    fd.append("page_title", state.page_title);
    fd.append("page_description", state.page_description);
    fd.append("keyword", state.keyword);

    if (state.updatedImage) {
      fd.append("image", state.updatedImage);
    }

    // fd.append("author_name", state.author_name);
    if (state.author_name) {
      console.log("state.author_name", state.author_name);
      let authors = state?.author_options?.filter(
        (author) => author._id == state.author_name
      );
      console.log("authors", authors);
      authors = authors[0];
      fd.append("author_name", authors["name"]);
      fd.append("author_image", authors["author_image"]);
      fd.append("author_slug", String(authors["slug"]));
      fd.append("author_id", authors["_id"]);
    }
    // if (state.updatedAuthorImage) {
    //   fd.append("author_image", state.updatedAuthorImage);
    // }
    if (state.slug) {
      fd.append("slug", state.slug);
    }

    if (state.category) {
      fd.append(
        "blog_category",
        JSON.stringify(state.category.map((item) => item.value))
      );
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    };

    axios
      .put(`${baseApi}blog/update-blog`, fd, config)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          loading: false
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          loading: false
        }));
        console.log("error.....", error);
        Myswal.fire({
          title: "Something went wrong.",
          text: error?.response?.data?.message || "Server Error",
          type: "error"
        });
      });
  };

  let image = state.tempImg;
  let isLoading = state.loading;
  let author_image = state.author_image_temp;

  const hStyle = { color: "red" };
  return (
    <Fragment>
      {!isLoading ? (
        <div className="admin_panel">
          <Helmet>
            <title>{state.page_title} | Dean Infotech Pvt. Ltd.</title>
            <meta
              id="meta-description"
              name="description"
              content={state.page_description}
            />
            <meta
              id="og-keywords"
              name="keywords"
              property="og:keywords"
              content={state.keyword}
            />
            <meta
              id="og-title"
              property="og:title"
              content={state.page_title}
            />
          </Helmet>
          <Dashboard />

          <div className="container">
            <div className="col-sm-12">
              <CurrentDate />
              <form
                onSubmit={onSubmit}
                encType="multipart/form-data"
                className="form"
              >
                <div className="form-group">
                  <label>
                    <h3>Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.b_title}
                    onChange={onchangeb_title}
                  />
                </div>

                <div className="form-group">
                  <label>
                    <h3>Slug</h3>
                  </label>
                  <input
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    className="form-control"
                    value={state.slug}
                    onChange={onchangeSlug}
                  />
                  <span style={hStyle}>{state.slugErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h4>Description</h4>
                  </label>
                  <ReactQuill
                    value={state.b_description}
                    onChange={onchangeb_description}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                  />
                </div>

                <div className="form-group">
                  <label>
                    <h3>Category</h3>
                  </label>
                  <Select
                    defaultValue={state.category}
                    isMulti
                    name="colors"
                    options={state.category_options}
                    className="form-Control select-search basic-multi-select"
                    classNamePrefix="select"
                    value={state.category}
                    onChange={onchangeCategory}
                  />
                  <span style={hStyle}>{state.categoryErr}</span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Author Name</h3>
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    value={state.author_name}
                    onChange={onchangeAuthorName}
                  >
                    <option value="">Select</option>
                    {state.author_options.map((author) => {
                      return <option value={author._id}>{author.name}</option>;
                    })}
                  </select>
                </div>

                {/* <div className="form-group">
                  <label>
                    <h4>Author Image</h4>
                  </label>
                  <input
                    type="file"
                    className="form-Control"
                    onChange={onchangeAuthorImage}
                    name="author_image"
                    accept="image/*"
                  />
                  <ImageWebp
                    srcWebp={author_image}
                    src={author_image}
                    className="blog1 mt-2"
                    height="80px"
                    width="100px"
                  />
                </div> */}

                <div className="form-group">
                  <label>
                    <h4>Image</h4>
                  </label>
                  <input
                    type="file"
                    className="form-Control"
                    onChange={onchangeImage}
                    name="Image"
                    accept="image/*"
                  />
                  <ImageWebp
                    srcWebp={image}
                    src={image}
                    className="blog1 mt-2"
                    height="80px"
                    width="100px"
                  />
                </div>
                <div className="form-group">
                  <label>
                    <h3>Page Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.page_title}
                    onChange={onchangePageTitle}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <h3>Page Description</h3>
                  </label>
                  <textarea
                    className="form-control"
                    value={state.page_description}
                    onChange={onchangePageDes}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label>
                    <h3>Keywords</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.keyword}
                    onChange={onchangeKeyword}
                  />
                </div>

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Update Blog"
                  />
                  &nbsp;&nbsp;
                  <Link to="/blogs">Back to Blogs</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default EditBlog;
