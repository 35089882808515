import React from "react";
import { Helmet } from "react-helmet";
import Dashboard from "./Dashboard";
import CurrentDate from "../CurrentDate";

const Header = ({ title, children }) => {
  return (
    <div className="admin_panel">
      <Helmet>
        <title>{title} | Dean Infotech Pvt. Ltd.</title>
        <meta id="meta-description" name="description" content={title} />
        <meta
          id="og-keywords"
          name="keywords"
          property="og:keywords"
          content={title}
        />
        <meta id="og-title" property="og:title" content={title} />
      </Helmet>
      <Dashboard />

      <div className="container">
        <div className="col-sm-12">
          {" "}
          <CurrentDate />
          <h3 align="center" className="admin_list_heading">
            {title}
          </h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
