import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Flip from "react-reveal/Flip";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import ImageWebp from "../../ImageWebp";
import "./Login.css";

const Login = ({ loginUser, auth, errors }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    title: "Admin",
    errors: {}
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate("/dashboard");
    }
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      errors
    }));
  }, [errors]);

  const onChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: state.email,
      password: state.password
    };

    loginUser(userData);
  };

  return (
    <>
      <Helmet>
        <title>{state.title} | Dean Infotech Pvt. Ltd.</title>
        <meta id="meta-description" name="description" content={state.title} />
        <meta
          id="og-keywords"
          name="keywords"
          property="og:keywords"
          content={state.title}
        />
        <meta id="og-title" property="og:title" content={state.title} />
      </Helmet>
      <div className="" id="d_login">
        <div className="wrapperlogin">
          <div className="containerlogin">
            <div className="logo_panel">
              <Link to="/admin">
                <ImageWebp
                  srcWebp={"./images/logo.png"}
                  src={"./images/logo.png"}
                />
              </Link>
            </div>

            <Flip right>
              <div className="">
                <div id="id" className="">
                  <h3 className="">Admin Panel</h3>
                </div>
                <form className="form" onSubmit={onSubmit}>
                  <div className="">
                    <b>
                      <span className="">
                        {state.errors.email}
                        {state.errors.emailnotfound}
                      </span>
                    </b>
                    <input
                      onChange={onChange}
                      value={state.email}
                      error={state.errors.email}
                      placeholder="Enter Email"
                      id="email"
                      type="email"
                      className={classnames("", {
                        invalid:
                          state.errors.email || state.errors.emailnotfound
                      })}
                    />
                  </div>

                  <div className="">
                    <b>
                      <span className="text-danger">
                        {state.errors.password}
                        {state.errors.passwordincorrect}
                      </span>
                    </b>
                    <input
                      onChange={onChange}
                      value={state.password}
                      error={state.errors.password}
                      id="password"
                      type="password"
                      className={classnames("", {
                        invalid:
                          state.errors.password ||
                          state.errors.passwordincorrect
                      })}
                      placeholder="Enter Password"
                    />
                  </div>

                  <div className="row m-login__form-sub"></div>
                  <div className="m-login__form m-form">
                    <div className="m-login__form-action">
                      <button
                        style={{
                          width: "150px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          marginTop: "1rem"
                        }}
                        type="submit"
                        className="btn btn-large btn-primary"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Flip>
            <Link to="/" className="btn-flat waves-effect">
              Return to Site
            </Link>
          </div>
          <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
