import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import ReactQuill from "react-quill";
import ImageWebp from "../../ImageWebp";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loader from "../Admin/Dashboard/Loader";
import { withRouter } from "react-router-dom";
import axios from "axios";
import baseApi from "../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactFileReader from "react-file-reader";
import "../../index.css";

const AddBlogCategory = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [webinar, setWebinar] = useState({});
  const [preview, setPreview] = useState();

  //   const { slug } = props.match.params;
  //   console.log("slug", slug);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page_title: "",
      page_description: "",
      blog_category: ""
    },
    validationSchema: Yup.object({
      page_title: Yup.string().required("Please enter page title"),
      page_description: Yup.string().required("Please enter page description"),
      blog_category: Yup.string().required("Please enter blog category")
    }),
    onSubmit: (values) => {
      setIsloading(true);
      const formData = new FormData();
      formData.append("page_title", values.page_title);
      formData.append("page_description", values.page_description);
      formData.append("blog_category", values.blog_category);

      axios
        .post(
          `${baseApi}blog/add-blog-category`,
          {
            blog_category: values.blog_category,
            page_description: values.page_description,
            page_title: values.page_title
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
            }
          }
        )
        .then((res) => {
          setIsloading(false);
          setPreview("");
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  return (
    <Fragment>
      <div className="admin_panel">
        <Dashboard />

        {!isLoading ? (
          <div className="container">
            <div className="col-sm-12">
              {" "}
              <CurrentDate />
              <form onSubmit={formik.handleSubmit} encType="application/json">
                <div className="form-group">
                  <label>
                    <h3>Blog Category</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="blog_category"
                    name="blog_category"
                    {...formik.getFieldProps("blog_category")}
                  />

                  <span className="text-danger">
                    {formik.touched.blog_category &&
                    formik.errors.blog_category ? (
                      <div>{formik.errors.blog_category}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_title"
                    name="page_title"
                    {...formik.getFieldProps("page_title")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_title && formik.errors.page_title ? (
                      <div>{formik.errors.page_title}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Description</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_description"
                    name="page_description"
                    {...formik.getFieldProps("page_description")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_description &&
                    formik.errors.page_description ? (
                      <div>{formik.errors.page_description}</div>
                    ) : null}
                  </span>
                </div>

                <hr />

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Add Blog Category"
                  />
                  &nbsp;&nbsp;
                  <Link to="/blog-category">Back to Blog Category</Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Fragment>
  );
};

export default AddBlogCategory;
