import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { jwtDecode } from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import NotFound from "./nofound";
//Routes for admin
import Blogs from "./Components/Admin/Dashboard/Blogs";
import AddnewBlog from "./Components/Admin/Dashboard/AddnewBlog";
import AddComment from "./Components/Comment/Comments";
import EditBlog from "./Components/Admin/Dashboard/editBlog";
import Login from "./Components/auth/Login";
import PrivateRoute from "./Components/private-route/PrivateRoute";
import Blog from "./Components/blog/blog";
import Webinars from "./Components/Webinar/Webinars";
import EditWebinar from "./Components/Webinar/EditWebinar";
import AddWebinar from "./Components/Webinar/AddWebinar";
import Events from "./Components/Event/Event";
import AddEvent from "./Components/Event/AddEvent";
import EditEvent from "./Components/Event/EditEvent";
import EditComment from "./Components/Comment/EditComment";
import ChangePassword from "./Components/ChangePassword/ChangePassword";
import BlogCategory from "./Components/BlogCategory/BlogCategoryList";
import AddBlogCategory from "./Components/BlogCategory/AddBlogCategory";
import EditBlogCategory from "./Components/BlogCategory/EditBlogCategory";
import BlogAuthor from "./Components/BlogAuthor/BlogAuthorList";
import AddBlogAuthor from "./Components/BlogAuthor/AddBlogAuthor";
import EditBlogAuthor from "./Components/BlogAuthor/EditBlogAuthor";

const referrerVal = document.referrer;
const skipReferrerDomain = ["deaninfotech.com", "localhost"];

if (referrerVal) {
  const isReferralExist = skipReferrerDomain.some((el) =>
    referrerVal.includes(el)
  );
  if (isReferralExist === false) {
    localStorage.setItem("referrer", document.referrer);
  }
}

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);

  const decoded = jwtDecode(token);

  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    window.location.href = "/admin";
  }
}
class Router extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin" element={<Login />} />

            <Route path="/" element={<Login />} />

            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Blogs />} />
              <Route path="/add-blog" element={<AddnewBlog />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/comments" element={<AddComment />} />
              <Route path="/edit-comment/:id" element={<EditComment />} />
              <Route path="/edit/:id" element={<EditBlog />} />
              <Route path="/blog/:id" element={<Blog />} />
              <Route path="/webinars" element={<Webinars />} />
              <Route path="/events" element={<Events />} />
              <Route path="/add-webinar" element={<AddWebinar />} />
              <Route path="/add-event" element={<AddEvent />} />
              <Route path="/edit-webinar/:slug" element={<EditWebinar />} />
              <Route path="/edit-event/:slug" element={<EditEvent />} />
              <Route path="/reset-password" element={<ChangePassword />} />
              <Route path="/blog-category" element={<BlogCategory />} />
              <Route path="/add-blog-category" element={<AddBlogCategory />} />
              <Route
                path="/edit-blog-category/:id"
                element={<EditBlogCategory />}
              />
              <Route path="/blog-author" element={<BlogAuthor />} />
              <Route path="/add-blog-author" element={<AddBlogAuthor />} />
              <Route
                path="/edit-blog-author/:id"
                element={<EditBlogAuthor />}
              />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  }
}
export default Router;
