import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import ReactQuill from "react-quill";
import ImageWebp from "../../ImageWebp";
import { useParams, Link, useNavigate } from "react-router-dom";
import Loader from "../Admin/Dashboard/Loader";
import { withRouter } from "react-router-dom";
import axios from "axios";
import baseApi from "../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactFileReader from "react-file-reader";
import "../../index.css";

const AddEvent = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [webinar, setWebinar] = useState({});
  const [preview, setPreview] = useState();

  //   const { slug } = props.match.params;
  //   console.log("slug", slug);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page_title: "",
      page_description: "",
      keywords: "",
      event_image: "",
      title: "",
      date: "",
      short_description: "",
      description: "",
      // body_text: "",
      upcoming: ""
    },
    validationSchema: Yup.object({
      page_title: Yup.string().required("Please enter page title"),
      page_description: Yup.string().required("Please enter page description"),
      keywords: Yup.string().required("Please enter keywords"),
      event_image: Yup.mixed().required("Please upload event image"),
      title: Yup.string().required("Please enter title"),
      date: Yup.string().required("Please enter date"),
      short_description: Yup.string().required(
        "Please enter short description"
      ),
      description: Yup.string().required("Please enter description"),
      // body_text: Yup.string().required("Please enter body text"),
      upcoming: Yup.boolean()
    }),
    onSubmit: (values) => {
      setIsloading(true);
      const formData = new FormData();
      formData.append("page_title", values.page_title);
      formData.append("page_description", values.page_description);
      formData.append("keywords", values.keywords);
      formData.append("event_image", values.event_image);
      formData.append("title", values.title);
      formData.append("date", values.date);
      formData.append("short_description", values.short_description);
      formData.append("description", values.description);
      // formData.append("body_text", values.body_text);
      if (values.upcoming !== "") {
        formData.append("upcoming", values.upcoming);
      }

      axios
        .post(`${baseApi}event-webinar/add-event`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
          }
        })
        .then((res) => {
          setIsloading(false);
          setPreview("");
          formik.resetForm();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const handleFile = (file) => {
    setPreview(file.base64);
    formik.setFieldValue("event_image", file.fileList[0]);
  };

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote", "code-block"], // toggled buttons
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }]
    ]
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "code-block",
    "font",
    "size",
    "list",
    "bullet",
    "align",
    "color",
    "background",
    "link",
    "image",
    "video"
  ];

  return (
    <Fragment>
      <div className="admin_panel">
        <Helmet>
          <title>{webinar.page_title || ""} | Dean Infotech Pvt. Ltd.</title>
          <meta
            id="meta-description"
            name="description"
            content={webinar.page_description || ""}
          />
          <meta
            id="og-keywords"
            name="keywords"
            property="og:keywords"
            content={webinar.keywords || ""}
          />
          <meta
            id="og-title"
            property="og:title"
            content={webinar.page_title || ""}
          />
        </Helmet>
        <Dashboard />

        {!isLoading ? (
          <div className="container">
            <div className="col-sm-12">
              {" "}
              <CurrentDate />
              <form onSubmit={formik.handleSubmit} encType="application/json">
                <div className="form-group">
                  <label>
                    <h3>Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    {...formik.getFieldProps("title")}
                  />

                  <span className="text-danger">
                    {formik.touched.title && formik.errors.title ? (
                      <div>{formik.errors.title}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Short Description</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="short_description"
                    name="short_description"
                    {...formik.getFieldProps("short_description")}
                  />

                  <span className="text-danger">
                    {formik.touched.short_description &&
                    formik.errors.short_description ? (
                      <div>{formik.errors.short_description}</div>
                    ) : null}
                  </span>
                </div>

                {/* <div className="form-group">
                  <label>
                    <h3>Body text</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="body_text"
                    name="body_text"
                    {...formik.getFieldProps("body_text")}
                  />

                  <span className="text-danger">
                    {formik.touched.body_text && formik.errors.body_text ? (
                      <div>{formik.errors.body_text}</div>
                    ) : null}
                  </span>
                </div> */}

                <div className="form-group">
                  <label>
                    <h3>Main content</h3>
                  </label>
                  <ReactQuill
                    // value={webinar.b_description}
                    className="editor-body"
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    id="description"
                    name="description"
                    onChange={(description) => {
                      formik.setFieldValue("description", description);
                    }}
                    // onBlur={formik.handleBlur}
                    value={formik.values.description}
                    // {...formik.getFieldProps("body_text")}
                  />
                  <span className="text-danger">
                    {formik.touched.description && formik.errors.description ? (
                      <div>{formik.errors.description}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label for="event_image">
                    {" "}
                    <h3>Event Image</h3>
                  </label>

                  <ReactFileReader
                    name="event_image"
                    fileTypes={[".png", ".jpg", ".jpeg"]}
                    handleFiles={handleFile}
                    handleBlur={formik.handleBlur}
                    base64={true}
                  >
                    <div>
                      <button
                        type="button"
                        className="upload-btn"
                        // onClick={(e) => e.preventDefault()}
                      >
                        {/* <img
                      src={require("../../../../assets/img/admin-images/upload.png")}
                    /> */}
                        <div>Upload Image</div>
                      </button>
                      {/* <input
                    type="file"
                    name="logo"
                    onChange={handleFile}
                    handleBlur={formik.handleBlur}
                  /> */}
                    </div>
                  </ReactFileReader>

                  <div>
                    {preview && <img className="preview" src={preview} />}
                  </div>

                  <div style={{ display: "block", color: "rgb(223, 58, 58)" }}>
                    {formik.touched.event_image && formik.errors.event_image ? (
                      <div>{formik.errors.event_image}</div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_title"
                    name="page_title"
                    {...formik.getFieldProps("page_title")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_title && formik.errors.page_title ? (
                      <div>{formik.errors.page_title}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Description</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_description"
                    name="page_description"
                    {...formik.getFieldProps("page_description")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_description &&
                    formik.errors.page_description ? (
                      <div>{formik.errors.page_description}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Keywords</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keywords"
                    name="keywords"
                    {...formik.getFieldProps("keywords")}
                  />

                  <span className="text-danger">
                    {formik.touched.keywords && formik.errors.keywords ? (
                      <div>{formik.errors.keywords}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Upcoming</h3>
                  </label>
                  <select
                    type="text"
                    className="form-control"
                    id="upcoming"
                    name="upcoming"
                    {...formik.getFieldProps("upcoming")}
                  >
                    <option value="" defaultValue="">
                      Select
                    </option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>

                  <span className="text-danger">
                    {formik.touched.upcoming && formik.errors.upcoming ? (
                      <div>{formik.errors.upcoming}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Date</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control w-30"
                    id="date"
                    name="date"
                    {...formik.getFieldProps("date")}
                  />

                  <span className="text-danger">
                    {formik.touched.date && formik.errors.date ? (
                      <div>{formik.errors.date}</div>
                    ) : null}
                  </span>
                </div>

                <hr />

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Add Event"
                  />
                  &nbsp;&nbsp;
                  <Link to="/events">Back to events</Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Fragment>
  );
};

export default AddEvent;
