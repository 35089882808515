import React, { Fragment } from "react";
import axios from "axios";
// import baseApi from "../../pages/api/api"
import baseApi from "../../api";
// import CommentForm from "./CommentForm"
// import CommentForm from "../../../../../../component/blogdetails/CommentForm"
// import PopularPost from "../blog/PopularPost"
// import PopularPost from "../../../../../../component/blog/PopularPost"
// import { useRouter } from "next/router"
// import LoaderPage from "../../pages/loader"
// import LoaderPage from "../../../loader"
// import Head from "next/head"
// import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
// import dateFormat from "dateformat"
import { useState } from "react";
import { useEffect } from "react";

// const swirly = "../images/swirly.png"
// const PageNotFound = require('../../../images/404-Page.gif');
import { Helmet } from "react-helmet";
import Dashboard from "../../Admin/Dashboard/Dashboard";
// import {
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton,
//   WhatsappShareButton
// } from "react-share";
import dateFormat from "dateformat";
import Loader from "../../Admin/Dashboard/Loader";
const logo = "/static/img/blog-logo.png";

export default function Bdetails() {
  const [blogDetails, setBlogDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // const [commentData, setCommentData] = useState(null)
  //   const router = useRouter()
  //   const { slug } = router.query

  //   console.log(slug)
  //   const slug = "5-tips-for-going-from-good-to-great-in-salesforce"
  //   const slug = "would-tableau-acquisition-would-change-the-future-of-salesforce-inc"

  useEffect(() => {
    let pathname = window.location.pathname.toString();
    pathname = pathname.substring(6, pathname.length);
    //     console.log("Path", pathname)
    if (pathname) {
      axios
        .get(baseApi + "blog/get-blog/" + pathname)
        // .get(baseApi + "b_blog/blog/" + slug)
        .then((res) => {
          // console.log(res);
          setBlogDetails({
            b_title: res.data.data.b_title,
            b_description: res.data.data.b_description,
            page_title: res.data.data.page_title,
            page_description: res.data.data.page_description,
            keyword: res.data.data.keyword,
            image: res.data.data.image,
            slug: res.data.data.slug,
            createdAt: res.data.data.createdAt
          });
          setLoading(false);
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    document.title = blogDetails.b_title;
  }, []);

  // console.log(blogDetails);

  const image = blogDetails.image;
  const url = "https://www.deaninfotech.com/blog";
  // const ogurl = `https://www.deaninfotech.com/blog/${blogDetails.slug}`

  return (
    <Fragment>
      {!loading ? (
        <div className="admin_panel">
          <Helmet>
            <title>{`${blogDetails.b_title}`} | Dean Infotech Pvt. Ltd.</title>
            <meta
              id="meta-description"
              name="description"
              content={`${blogDetails.b_description}`}
            />
            <meta
              id="og-keywords"
              name="keywords"
              property="og:keywords"
              content={`${blogDetails.keyword}`}
            />
            <meta
              id="og-title"
              property="og:title"
              content={`${blogDetails.b_title}`}
            />
          </Helmet>
          <Dashboard />

          <div className="container">
            <div className="col-sm-12">
              <div className="card">
                <h2>{blogDetails.b_title}</h2>
                <h5>
                  <img src={logo} alt="Dean Infotech" className="logo_blog" />
                  Dean Infotech{" "}
                  <span>
                    <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                    {dateFormat(blogDetails.createdAt, "fullDate")}
                  </span>
                </h5>
                <div className="blog_img blog-img">
                  <img src={image} alt="" className="blog1" />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: blogDetails.b_description
                  }}
                  className="for_description_div"
                ></div>
                <div className="share_link_blog">
                  {/* <span>
                    <FacebookShareButton
                      url={url}
                      quote={blogDetails.b_title}
                      hashing="#{blogDetails?.page_description}"
                    >
                      <i className="fa fa-facebook hvr-wobble-vertical"></i>
                    </FacebookShareButton>
                  </span>
                  <span>
                    <TwitterShareButton url={url} title={blogDetails.b_title}>
                      <i className="fa fa-twitter hvr-wobble-vertical"></i>
                    </TwitterShareButton>
                  </span>
                  <span>
                    <LinkedinShareButton url={url} title={blogDetails.b_title}>
                      <i
                        className="fa fa-linkedin hvr-wobble-vertical"
                        style={{ background: "#4875B4", color: "white" }}
                      ></i>
                    </LinkedinShareButton>
                  </span>
                  <span>
                    <WhatsappShareButton
                      url={url}
                      title={blogDetails.b_title}
                      separator="- "
                    >
                      <i
                        className="fa fa-whatsapp hvr-wobble-vertical"
                        style={{ background: "#25D366", color: "white" }}
                      ></i>
                    </WhatsappShareButton>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
}
