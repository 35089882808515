import axios from "axios";
import React, { Fragment, useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table/Table";
import { Helmet } from "react-helmet";
import Dashboard from "./Dashboard";
import CurrentDate from "../CurrentDate";
import Header from "./Header";
import baseApi from "../../api";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";

const base_url = process.env.REACT_APP_BASE_URL;
const Blogs = () => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsloading] = useState(false);
  const [itemTODelete, setItemToDelete] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEdit = (id) => {
    // dispatch(appActions.setActiveEditLocationId(id));
    // localStorage.setItem("activeEditLocationId", id);
    // dispatch(appActions.setActiveEditType("Company"));
    // localStorage.setItem("activeEditType", "Company");
    // navigate("/editLocation");
  };

  const handleDelete = (id) => {
    setIsloading(true);
    let reqObj = {
      method: "DELETE",
      url: `${baseApi}blog/remove-blog/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    };

    axios(reqObj)
      .then((res) => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  // const htmlToPlainText = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };

  const columns = [
    {
      name: "Title",
      selector: (row) => `${row.b_title}`,
      sortable: true,
      width: "330px",
      wrap: true
    },
    {
      name: "Description",
      selector: (row) =>
        `${row.b_description
          .replace(/<[^>]+>/g, "")
          .replace("&nbsp;", " ")
          .substring(0, 80)}...`,
      sortable: true,
      width: "330px",
      wrap: true
    },
    {
      name: "Image",
      selector: (row) => (
        <img style={{ width: "100px", height: "60px" }} src={row.image} />
      ),
      sortable: false,
      width: "150px"
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD MMM YYYY"),
      sortable: true,
      width: "140px"
    },
    {
      name: "Action",
      width: "140px",
      cell: (row) => (
        <Fragment>
          <div>
            <div>
              <a
                href="#0"
                style={{ cursor: "pointer" }}
                // onClick={() => handleDelete(row._id)}
                onClick={() => {
                  setItemToDelete(row._id);
                  openModal();
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
              &nbsp;&nbsp;&nbsp;
              <Link to={"/edit/" + row._id}>
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link to={"/blog/" + row.slug}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </Fragment>
      )
    }
  ];
  const options = {
    // title: activeCompany.name,
    title: "Blogs",
    type: "Blog",
    columns: columns,
    apiUrl: `${baseApi}blog/list-blog-admin`,
    showAddBtn: true,
    AddBtnUrl: "/add-blog"
  };

  return (
    <Fragment>
      <Header title="blog list">
        <Table options={options} isLoading={isLoading} />
        <ConfirmModal
          isOpen={modalOpen}
          onClose={closeModal}
          type={"Blog"}
          content={"Are you sure you want to delete this blog?"}
          handleDelete={handleDelete}
          id={itemTODelete}
        />
      </Header>
    </Fragment>
  );
};

export default Blogs;
