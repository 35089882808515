import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ auth }) => {
  return (
    <>{auth.isAuthenticated === true ? <Outlet /> : <Navigate to="/admin" />}</>
  );
};

// export default PrivateRoutes;

// const PrivateRoute = ({ component: Component, auth, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       auth.isAuthenticated === true ? (
//         <Component {...props} />
//       ) : (
//         <Navigate to="/admin" />
//       )
//     }
//   />
// );

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
