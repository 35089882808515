import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import ReactQuill from "react-quill";
import ImageWebp from "../../ImageWebp";
import Loader from "../Admin/Dashboard/Loader";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import baseApi from "../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactFileReader from "react-file-reader";
import "../../index.css";

const EditBlogCategory = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [BlogCategory, setBlogCategory] = useState({});
  const [preview, setPreview] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  // console.log("slug", slug);
  // console.log("preview", preview);

  useEffect(() => {
    axios.get(`${baseApi}blog/get-blog-category/${id}`).then((res) => {
      setBlogCategory(res.data.data);
      setIsloading(false);
      console.log("Res", res);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      page_title: BlogCategory.page_title,
      page_description: BlogCategory.page_description,
      blog_category: BlogCategory.blog_category
    },
    validationSchema: Yup.object({
      page_title: Yup.string().required("Please enter page title"),
      page_description: Yup.string().required("Please enter page description"),
      blog_category: Yup.string().required("Please enter blog category")
    }),
    onSubmit: (values) => {
      setIsloading(true);
      const payload = {
        blog_category_id: BlogCategory._id,
        blog_category: values.blog_category,
        page_title: values.page_title,
        page_description: values.page_description
      };

      axios
        .put(`${baseApi}blog/update-blog-category`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
          }
        })
        .then((res) => {
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  return (
    <Fragment>
      <div className="admin_panel">
        <Dashboard />

        {!isLoading ? (
          <div className="container">
            <div className="col-sm-12">
              {" "}
              <CurrentDate />
              <form onSubmit={formik.handleSubmit} encType="application/json">
                <div className="form-group">
                  <label>
                    <h3>Blog Category</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="blog_category"
                    name="blog_category"
                    {...formik.getFieldProps("blog_category")}
                  />

                  <span className="text-danger">
                    {formik.touched.blog_category &&
                    formik.errors.blog_category ? (
                      <div>{formik.errors.blog_category}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Title</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_title"
                    name="page_title"
                    {...formik.getFieldProps("page_title")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_title && formik.errors.page_title ? (
                      <div>{formik.errors.page_title}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Page Description</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="page_description"
                    name="page_description"
                    {...formik.getFieldProps("page_description")}
                  />

                  <span className="text-danger">
                    {formik.touched.page_description &&
                    formik.errors.page_description ? (
                      <div>{formik.errors.page_description}</div>
                    ) : null}
                  </span>
                </div>

                <hr />

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Update Blog Category"
                  />
                  &nbsp;&nbsp;
                  <Link to="/blog-category">Back to Blog Category</Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Fragment>
  );
};

export default EditBlogCategory;
