import axios from "axios";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpin from "react-loading-spin";
import "./Table.css";

function DatatableLoader() {
  let size = "45px";
  return (
    <Fragment>
      <div style={{ padding: "40px" }}>
        <div className="spinning-loader">
          <LoadingSpin
            duration="2s"
            width="5px"
            timingFunction="ease-in-out"
            direction="alternate"
            size={size}
            primaryColor="rgb(0, 96, 144) rgb(0, 126, 255)"
            secondaryColor=" red rgb(32 149 207)"
            numberOfRotationsInAnimation={2}
          />
        </div>
      </div>
    </Fragment>
  );
}

const tableTop = (
  <Fragment>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2>Title</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p>Employee Directory</p>
        <p>Location</p>
      </div>
    </div>
  </Fragment>
);

const Table = ({ options, isLoading }) => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { title, type, showActions, columns, apiUrl, showAddBtn, AddBtnUrl } =
    options;

  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [sortByColumn, setSortByColumn] = useState("createdAt");
  const [sortByDir, setSortByDir] = useState();
  const [isInitialize, setIsInitialize] = useState(false);

  let postdata = {
    search: "",
    page: 1,
    limit: 10
    // sortBy: "createdAt",
    // sortOrder: "1"
  };

  const fetchApiData = async (
    page,
    size = perPage,
    sortBy = sortByColumn,
    sortOrder = sortByDir,
    searchKw = filterText
  ) => {
    setLoading(true);

    postdata["page"] = page;
    postdata["limit"] = size;
    postdata["search"] = searchKw;
    if (sortBy !== "") {
      postdata["sortBy"] = sortBy;
    }
    if (sortOrder !== "") {
      postdata["sortOrder"] = sortOrder;
    }

    /*
    const handleSort = (column, sortDirection) => {
      // console.log("column", column?.selector?.toString());
      // console.log("sortDirection", sortDirection);
      if ("id" in column && isInitialize !== false) {
        let sortIndex = "";
        let indexStr = column.selector.toString();
        if (indexStr.includes("moment")) {
          // console.log(indexStr.substring(indexStr.indexOf("row.") + 4, indexStr.indexOf(").format")));
          sortIndex = indexStr.substring(
            indexStr.indexOf("row.") + 4,
            indexStr.indexOf(").format")
          );
        } else {
          sortIndex = column.selector
            .toString()
            .substring(11, column.selector.toString().length);
        }

        setSortByDir(sortDirection);
        setSortByColumn(sortIndex);
        fetchApiData(currentPage, perPage, sortIndex, sortDirection).catch(
          (err) => {
            setLoading(false);
          }
        );
      }
    };

    */

    const response = await axios.post(apiUrl, postdata, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
      }
    });
    // console.log("response", response);
    setData(response.data.data);
    setTotalRows(response.data.totalCount);

    setLoading(false);
    setIsInitialize(true);
  };

  useEffect(() => {
    fetchApiData(1).catch((err) => {
      console.log("TableError", err);
      setLoading(false);
    });
  }, [isLoading, sortByColumn]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Fragment>
        <div className="table-header">
          <div className={`form-group has-search m-2 table-search`}>
            <span className={` form-control-feedback`}>
              <img
                src={require("../../images/search.png")}
                className="cursor-pointer mt-1"
              />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                setFilterText(e.target.value);
                setCurrentPage(1);
                fetchApiData(
                  1,
                  perPage,
                  sortByColumn,
                  sortByDir,
                  e.target.value
                ).catch((err) => {
                  setLoading(false);
                });
              }}
              value={filterText}
            />
          </div>

          {showAddBtn && (
            <Link to={AddBtnUrl}>
              <button className="add-btn">Add {type}</button>
            </Link>
          )}
        </div>
      </Fragment>
    );
  }, [filterText]);

  const handlePageChange = (page) => {
    if (isInitialize !== false) {
      fetchApiData(page).catch((err) => {
        setLoading(false);
      });
      setCurrentPage(page);
    }
  };

  const handlePerRowsChange = (newPerPage, page) => {
    if (isInitialize !== false) {
      fetchApiData(page, newPerPage).catch((err) => {
        setLoading(false);
      });
      setPerPage(newPerPage);
    }
  };

  const handleSort = (column, sortDirection) => {
    // console.log("column", column?.selector?.toString());
    // console.log("sortDirection", sortDirection);
    if ("id" in column && isInitialize !== false) {
      let sortIndex = "";
      let indexStr = column.selector.toString();
      if (indexStr.includes("moment")) {
        // console.log(indexStr.substring(indexStr.indexOf("row.") + 4, indexStr.indexOf(").format")));
        sortIndex = indexStr.substring(
          indexStr.indexOf("row.") + 4,
          indexStr.indexOf(").format")
        );
      } else if (indexStr.includes("...")) {
        const firstDotIndex = column.selector.toString().indexOf(".");

        // Find the index of the second dot, starting the search after the first dot
        const secondDotIndex = column.selector
          .toString()
          .indexOf(".", firstDotIndex + 1);
        sortIndex = column.selector.toString().substring(42, secondDotIndex);
        // console.log("sortIndex", sortIndex);
      } else {
        // console.log("column", column.selector.toString());
        sortIndex = column.selector
          .toString()
          .substring(
            column.selector.toString().indexOf(".") + 1,
            column.selector.toString().indexOf(";")
          );
        // console.log("sortIndex", sortIndex);
      }

      setSortByDir(sortDirection);
      setSortByColumn(sortIndex);
      fetchApiData(currentPage, perPage, sortIndex, sortDirection).catch(
        (err) => {
          setLoading(false);
        }
      );
    }
  };

  const handleRowClicked = (row) => {
    if (type == "Building") {
      //   dispatch(appActions.setActiveLocation(row));
      localStorage.setItem("activeLocation", JSON.stringify({ ...row }));
      //   navigate("/CompanyEmployeeDirectory");
    }
  };

  const tableActions = (
    <Fragment>
      <div>
        <div
          style={{ cursor: "pointer" }}
          // onClick={() => navigate("/terms")}
        >
          {/* <img src={require("../../../../assets/img/admin-images/copy.png")} /> */}
          <span>Terms</span>
        </div>
      </div>
    </Fragment>
  );
  console.log("data", data);
  return (
    <DataTable
      title={title}
      columns={columns}
      data={data}
      progressPending={loading}
      progressComponent={<DatatableLoader />}
      pagination={true}
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      paginationPerPage={10}
      fixedHeader
      fixedHeaderScrollHeight="300px"
      onRowClicked={handleRowClicked}
      conditionalRowStyles={[
        {
          when: (row) => true,
          style: {
            height: "80px",
            fontSize: "13px"
          }
        }
      ]}
      // conditionalRowStyles={[
      //   {
      //     when: (row) => true,
      //     style: {
      //       backgroundColor: "#ffffff",
      //       margin: "8px",
      //       boxShadow: "0px 0px 1px #707070",
      //       color: "#707070",
      //       "&:hover": {
      //         cursor: "pointer"
      //       },
      //       cursor: "pointer"
      //     }
      //   }
      // ]}
      actions={showActions ? tableActions : ""}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      subHeaderAlign="left"
    />
  );
};

export default Table;
