import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Dashboard from "../Admin/Dashboard/Dashboard";
import CurrentDate from "../Admin/CurrentDate";
import ReactQuill from "react-quill";
import ImageWebp from "../../ImageWebp";
import Loader from "../Admin/Dashboard/Loader";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import baseApi from "../api";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactFileReader from "react-file-reader";
import "../../index.css";

const EditBlogAuthor = (props) => {
  const [isLoading, setIsloading] = useState(false);
  const [BlogAuthor, setBlogAuthor] = useState({});
  const [preview, setPreview] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  // console.log("slug", slug);
  // console.log("preview", preview);

  useEffect(() => {
    axios.get(`${baseApi}blog/get-blog-author/${id}`).then((res) => {
      setBlogAuthor(res.data.data);
      setPreview(res.data.data.author_image);
      setIsloading(false);
      console.log("Res", res);
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: BlogAuthor.name || "",
      designation: BlogAuthor.designation || "",
      bio: BlogAuthor.bio || "",
      link: BlogAuthor.link || "",
      author_image: BlogAuthor.author_image || ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter author name"),
      designation: Yup.string().required("Please enter designation"),
      bio: Yup.string().required("Please enter author bio"),
      link: Yup.string(),
      author_image: Yup.mixed().required("Please upload author image")
    }),
    onSubmit: (values) => {
      setIsloading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("designation", values.designation);
      formData.append("bio", values.bio);
      formData.append("author_image", values.author_image);
      formData.append("author_id", BlogAuthor._id);
      if (values.link) {
        formData.append("link", values.link);
      }

      axios
        .put(`${baseApi}blog/update-blog-author`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`
          }
        })
        .then((res) => {
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const handleFile = (file) => {
    setPreview(file.base64);
    formik.setFieldValue("author_image", file.fileList[0]);
  };

  return (
    <Fragment>
      <div className="admin_panel">
        <Dashboard />

        {!isLoading ? (
          <div className="container">
            <div className="col-sm-12">
              {" "}
              <CurrentDate />
              <form onSubmit={formik.handleSubmit} encType="application/json">
                <div className="form-group">
                  <label>
                    <h3>Author Name</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    {...formik.getFieldProps("name")}
                  />

                  <span className="text-danger">
                    {formik.touched.name && formik.errors.name ? (
                      <div>{formik.errors.name}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Designation</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    name="designation"
                    {...formik.getFieldProps("designation")}
                  />

                  <span className="text-danger">
                    {formik.touched.designation && formik.errors.designation ? (
                      <div>{formik.errors.designation}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>Bio</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bio"
                    name="bio"
                    {...formik.getFieldProps("bio")}
                  />

                  <span className="text-danger">
                    {formik.touched.bio && formik.errors.bio ? (
                      <div>{formik.errors.bio}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label>
                    <h3>LinkedIn</h3>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    name="link"
                    {...formik.getFieldProps("link")}
                  />

                  <span className="text-danger">
                    {formik.touched.link && formik.errors.link ? (
                      <div>{formik.errors.link}</div>
                    ) : null}
                  </span>
                </div>

                <div className="form-group">
                  <label for="author_image">
                    {" "}
                    <h3>Author Image</h3>
                  </label>

                  <ReactFileReader
                    name="author_image"
                    fileTypes={[".png", ".jpg", ".jpeg"]}
                    handleFiles={handleFile}
                    handleBlur={formik.handleBlur}
                    base64={true}
                  >
                    <div>
                      <button
                        type="button"
                        className="upload-btn"
                        // onClick={(e) => e.preventDefault()}
                      >
                        {/* <img
                      src={require("../../../../assets/img/admin-images/upload.png")}
                    /> */}
                        <div>Upload Image</div>
                      </button>
                      {/* <input
                    type="file"
                    name="logo"
                    onChange={handleFile}
                    handleBlur={formik.handleBlur}
                  /> */}
                    </div>
                  </ReactFileReader>

                  <div>
                    {preview && <img className="preview" src={preview} />}
                  </div>

                  <div style={{ display: "block", color: "rgb(223, 58, 58)" }}>
                    {formik.touched.author_image &&
                    formik.errors.author_image ? (
                      <div>{formik.errors.author_image}</div>
                    ) : null}
                  </div>
                </div>

                <hr />

                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Update Blog Author"
                  />
                  &nbsp;&nbsp;
                  <Link to="/blog-author">Back to Blog Author</Link>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Fragment>
  );
};

export default EditBlogAuthor;
